:root {
  --toastify-toast-width: 320px;
  --toastify-toast-min-height: 55px;
}

.Toastify__toast--error {
  background: #ffe7d9;
}

.Toastify__toast--success {
  background: #e9fcd4;
}

.Toastify__toast--warning {
  background: #fff7cd;
}
